import { ILangWidget } from 'src/types/product';

export const LangProductHead: ILangWidget = [
  {
    feId: 'ProductHeadBrandName',
    desc: [
      { lang: 'en', val: 'Brand Name' },
      { lang: 'de', val: 'Markenname' },
      { lang: 'es', val: 'Nombre de la Marca' },
      { lang: 'fr', val: 'Nom de Marque' },
      { lang: 'it', val: 'Nome del Marchio' },
    ],
    value: [
      { lang: 'en', val: ['Brand Name'] },
      { lang: 'de', val: ['Markenname'] },
      { lang: 'es', val: ['Nombre de la Marca'] },
      { lang: 'fr', val: ['Nom de Marque'] },
      { lang: 'it', val: ['Nome del Marchio'] },
    ],
  },
  {
    feId: 'ProductHeadProductName',
    desc: [
      { lang: 'en', val: 'Product Name' },
      { lang: 'de', val: 'Produktname' },
      { lang: 'es', val: 'Nombre del Producto' },
      { lang: 'fr', val: 'Nom du Produit' },
      { lang: 'it', val: 'Nome del Prodotto' },
    ],
    value: [
      { lang: 'en', val: ['Product Name'] },
      { lang: 'de', val: ['Produktname'] },
      { lang: 'es', val: ['Nombre del Producto'] },
      { lang: 'fr', val: ['Nom du Produit'] },
      { lang: 'it', val: ['Nome del Prodotto'] },
    ],
  },
  {
    feId: 'ShowProductTags',
    desc: [
      { lang: 'en', val: 'Show Product Tags' },
      { lang: 'de', val: 'Produkt-Tags anzeigen' },
      { lang: 'es', val: 'Mostrar etiquetas del producto' },
      { lang: 'fr', val: 'Afficher les étiquettes du produit' },
      { lang: 'it', val: 'Mostra etichette del prodotto' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
      { lang: 'es', val: ['X'] },
      { lang: 'fr', val: ['X'] },
      { lang: 'it', val: ['X'] },
    ],
  },
  {
    feId: 'ProductHeadProductTags',
    desc: [
      { lang: 'en', val: 'Product Tags' },
      { lang: 'de', val: 'Produkt-Tags' },
      { lang: 'es', val: 'Etiquetas del Producto' },
      { lang: 'fr', val: 'Étiquettes du Produit' },
      { lang: 'it', val: 'Etichette del Prodotto' },
    ],
    value: [
      { lang: 'en', val: ['Tag1', 'Tag2'] },
      { lang: 'de', val: ['Tag1', 'Tag2'] },
      { lang: 'es', val: ['Tag1', 'Tag2'] },
      { lang: 'fr', val: ['Tag1', 'Tag2'] },
      { lang: 'it', val: ['Tag1', 'Tag2'] },
    ],
  },
  {
    feId: 'ProductPicture',
    desc: [
      { lang: 'en', val: 'Product Picture' },
      { lang: 'de', val: 'Produktbild' },
      { lang: 'es', val: 'Imagen del Producto' },
      { lang: 'fr', val: 'Image du Produit' },
      { lang: 'it', val: 'Immagine del Prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ProductBanner',
    desc: [
      { lang: 'en', val: 'Product Banner' },
      { lang: 'de', val: 'Produktbanner' },
      { lang: 'es', val: 'Banner del Producto' },
      { lang: 'fr', val: 'Bannière du Produit' },
      { lang: 'it', val: 'Banner del Prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ProductFilename',
    desc: [
      { lang: 'en', val: 'Product Filename' },
      { lang: 'de', val: 'Produktdateiname' },
      { lang: 'es', val: 'Nombre de Archivo del Producto' },
      { lang: 'fr', val: 'Nom de Fichier du Produit' },
      { lang: 'it', val: 'Nome del File del Prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'BannerFilename',
    desc: [
      { lang: 'en', val: 'Banner Filename' },
      { lang: 'de', val: 'Bannerdateiname' },
      { lang: 'es', val: 'Nombre de Archivo del Banner' },
      { lang: 'fr', val: 'Nom de Fichier de la Bannière' },
      { lang: 'it', val: 'Nome del File del Banner' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ShowProduct',
    desc: [
      { lang: 'en', val: 'Show Product' },
      { lang: 'de', val: 'Produkt anzeigen' },
      { lang: 'es', val: 'Mostrar Producto' },
      { lang: 'fr', val: 'Afficher le Produit' },
      { lang: 'it', val: 'Mostra Prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ShowBanner',
    desc: [
      { lang: 'en', val: 'Show Banner' },
      { lang: 'de', val: 'Banner anzeigen' },
      { lang: 'es', val: 'Mostrar Banner' },
      { lang: 'fr', val: 'Afficher la Bannière' },
      { lang: 'it', val: 'Mostra Banner' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
];