//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const FooterWidget: IWidget = {
  id: uuid(),
  name: 'FooterWidget',
  order: 0,
  drop: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'footer',
    name: 'Footer',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        'WebsiteURL': {
          feId: 'WebsiteURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WebsiteURL'),
          value: getValue('WebsiteURL'),
        },
        'ShowWebsiteURL': {
          feId: 'ShowWebsiteURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowWebsiteURL'),
          value: getValue('ShowWebsiteURL'),
        },
        'XURL': {
          feId: 'XURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('XURL'),
          value: getValue('XURL'),
        },
        'ShowXURL': {
          feId: 'ShowXURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowXURL'),
          value: getValue('ShowXURL'),
        },
        'InstagramURL': {
          feId: 'InstagramURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InstagramURL'),
          value: getValue('InstagramURL'),
        },
        'ShowInstagramURL': {
          feId: 'ShowInstagramURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowInstagramURL'),
          value: getValue('ShowInstagramURL'),
        },
        'FacebookURL': {
          feId: 'FacebookURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('FacebookURL'),
          value: getValue('FacebookURL'),
        },
        'ShowFacebookURL': {
          feId: 'ShowFacebookURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowFacebookURL'),
          value: getValue('ShowFacebookURL'),
        },
        'LinkedInURL': {
          feId: 'LinkedInURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('LinkedinURL'),
          value: getValue('LinkedinURL'),
        },
        'ShowLinkedInURL': {
          feId: 'ShowLinkedInURL',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowLinkedinURL'),
          value: getValue('ShowLinkedinURL'),
        },
        'WebsiteName': {
          feId: 'WebsiteName',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WebsiteName'),
          value: getValue('WebsiteName'),
        },
        'Imprint': {
          feId: 'Imprint',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Imprint'),
          value: getValue('Imprint'),
        },
      },
    },
  ],
  style: {
    'Orientation': {
      name: 'Orientation',
      value: ['center'],
    },
    'ShowRightsReserved': {
      name: 'Show Rights Reserved',
      value: ['X'],
    },
    'Logo': {
      name: 'Logo',
      value: [''],
    },
    'ShowLogo': {
      name: 'Show Logo',
      value: [''],
    },
    'LogoFilename': {
      name: 'Logo Filename',
      value: [''],
    },
  },
};
