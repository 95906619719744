import { ILangWidget } from 'src/types/product';

export const LangDocument: ILangWidget = [
  {
    feId: 'DocumenFile',
    desc: [
      { lang: 'en', val: 'Document File' },
      { lang: 'de', val: 'Dokument' },
      { lang: 'es', val: 'Document' },
      { lang: 'fr', val: 'Document' },
      { lang: 'it', val: 'Document' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'DocumentPath',
    desc: [
      { lang: 'en', val: 'Document Path' },
      { lang: 'de', val: 'Dokument' },
      { lang: 'es', val: 'Document' },
      { lang: 'fr', val: 'Document' },
      { lang: 'it', val: 'Document' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'DocumentName',
    desc: [
      { lang: 'en', val: 'Document Name' },
      { lang: 'de', val: 'Dokumentenname' },
      { lang: 'es', val: 'Nombre del documento' },
      { lang: 'fr', val: 'Nom du document' },
      { lang: 'it', val: 'Nome del documento' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
];