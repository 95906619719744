import { ILangWidget } from 'src/types/product';

export const LangShowcaseVideo: ILangWidget = [
  {
    feId: 'YoutubeEmbedID',
    desc: [
      { lang: 'en', val: 'Youtube Embed ID' },
      { lang: 'de', val: 'Youtube Embed ID' },
      { lang: 'es', val: 'Youtube Embed ID' },
      { lang: 'fr', val: 'Youtube Embed ID' },
      { lang: 'it', val: 'Youtube Embed ID' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
];