import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

export const Origin: IWidget = {
  id: uuid(),
  name: 'Origin',
  order: 0,
  drop: 0,
  category: 'Sustainability',
  label: {
    id: 'origin',
    name: 'Origin',
    color: 'black',
  },
  open: false,
  childs: [
    {
      id: 'Head',
      open: false,
      show: true,
      attributes: {
        WidgetHeadline_Origin: {
          feId: 'WidgetHeadline_Origin',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Origin'),
          value: getValue('WidgetHeadline_Origin'),
        },
        ShowMap: {
          feId: 'ShowMap',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowMap'),
          value: getValue('ShowMap'),
        },
      },
    },
    {
      id: 'Production',
      open: false,
      show: true,
      attributes: {
        WidgetHeadline_Production: {
          feId: 'WidgetHeadline_Production',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Production'),
          value: getValue('WidgetHeadline_Production'),
        },
        WidgetDescription_Production: {
          feId: 'WidgetDescription_Production',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetDescription_Production'),
          value: getValue('WidgetDescription_Production'),
        },
        ProductionPlaces: {
          feId: 'ProductionPlaces',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('ProductionPlaces'),
          value: getValue('ProductionPlaces'),
        },
      },
    },
    {
      id: 'Ressources',
      open: false,
      show: true,
      attributes: {
        WidgetHeadline_Ressources: {
          feId: 'WidgetHeadline_Ressources',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Ressources'),
          value: getValue('WidgetHeadline_Ressources'),
        },
        WidgetDescription_Ressources: {
          feId: 'WidgetDescription_Ressources',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetDescription_Ressources'),
          value: getValue('WidgetDescription_Ressources'),
        },
        RessourcesPlaces: {
          feId: 'RessourcesPlaces',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('RessourcesPlaces'),
          value: getValue('RessourcesPlaces'),
        },
      },
    },
    {
      id: 'Packaging',
      open: false,
      show: true,
      attributes: {
        WidgetHeadline_Packaging: {
          feId: 'WidgetHeadline_Packaging',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Packaging'),
          value: getValue('WidgetHeadline_Packaging'),
        },
        WidgetDescription_Packaging: {
          feId: 'WidgetDescription_Packaging',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetDescription_Packaging'),
          value: getValue('WidgetDescription_Packaging'),
        },
        PackagingPlaces: {
          feId: 'PackagingPlaces',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('PackagingPlaces'),
          value: getValue('PackagingPlaces'),
        },
      },
    },
  ],
  style: {
    objects: {
      name: '',
      value: [],
    },
  },
};
