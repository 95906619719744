import { ILangWidget } from 'src/types/product';

export const LangOthers: ILangWidget = [
  {
    feId: 'Patos',
    desc: [
      { lang: 'en', val: '' },
      { lang: 'de', val: '' },
      { lang: 'es', val: '' },
      { lang: 'fr', val: '' },
      { lang: 'it', val: '' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'Messe',
    desc: [
      { lang: 'en', val: '' },
      { lang: 'de', val: '' },
      { lang: 'es', val: '' },
      { lang: 'fr', val: '' },
      { lang: 'it', val: '' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'WidgetHeadline_Recycling',
    desc: [
      { lang: 'en', val: 'Recycling' },
      { lang: 'de', val: 'Recycling' },
      { lang: 'es', val: 'Reciclaje' },
      { lang: 'fr', val: 'Recyclage' },
      { lang: 'it', val: 'Riciclaggio' },
    ],
    value: [
      { lang: 'en', val: ['Recycling'] },
      { lang: 'de', val: ['Korrekte Entsorgung'] },
      { lang: 'es', val: ['Reciclaje'] },
      { lang: 'fr', val: ['Recyclage'] },
      { lang: 'it', val: ['Riciclaggio'] },
    ],
  },
];