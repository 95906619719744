import { ILangWidget } from 'src/types/product';

export const LangShowcaseCarousel: ILangWidget = [
  {
    feId: 'Ratio',
    desc: [
      { lang: 'en', val: 'Ratio' },
      { lang: 'de', val: 'Verhältnis' },
      { lang: 'es', val: 'Relación' },
      { lang: 'fr', val: 'Ratio' },
      { lang: 'it', val: 'Rapporto' },
    ],
    value: [
      { lang: 'en', val: ['1/1'] },
      { lang: 'de', val: ['1/1'] },
      { lang: 'es', val: ['1/1'] },
      { lang: 'fr', val: ['1/1'] },
      { lang: 'it', val: ['1/1'] },
    ],
  },
  {
    feId: 'Image1',
    desc: [
      { lang: 'en', val: 'Image 1' },
      { lang: 'de', val: 'Bild 1' },
      { lang: 'es', val: 'Imagen 1' },
      { lang: 'fr', val: 'Image 1' },
      { lang: 'it', val: 'Immagine 1' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Img1File',
    desc: [
      { lang: 'en', val: 'Image 1 File' },
      { lang: 'de', val: 'Bild 1 Datei' },
      { lang: 'es', val: 'Archivo de Imagen 1' },
      { lang: 'fr', val: 'Fichier Image 1' },
      { lang: 'it', val: 'File Immagine 1' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Image2',
    desc: [
      { lang: 'en', val: 'Image 2' },
      { lang: 'de', val: 'Bild 2' },
      { lang: 'es', val: 'Imagen 2' },
      { lang: 'fr', val: 'Image 2' },
      { lang: 'it', val: 'Immagine 2' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Img2File',
    desc: [
      { lang: 'en', val: 'Image 2 File' },
      { lang: 'de', val: 'Bild 2 Datei' },
      { lang: 'es', val: 'Archivo de Imagen 2' },
      { lang: 'fr', val: 'Fichier Image 2' },
      { lang: 'it', val: 'File Immagine 2' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Image3',
    desc: [
      { lang: 'en', val: 'Image 3' },
      { lang: 'de', val: 'Bild 3' },
      { lang: 'es', val: 'Imagen 3' },
      { lang: 'fr', val: 'Image 3' },
      { lang: 'it', val: 'Immagine 3' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Img3File',
    desc: [
      { lang: 'en', val: 'Image 3 File' },
      { lang: 'de', val: 'Bild 3 Datei' },
      { lang: 'es', val: 'Archivo de Imagen 3' },
      { lang: 'fr', val: 'Fichier Image 3' },
      { lang: 'it', val: 'File Immagine 3' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Image4',
    desc: [
      { lang: 'en', val: 'Image 4' },
      { lang: 'de', val: 'Bild 4' },
      { lang: 'es', val: 'Imagen 4' },
      { lang: 'fr', val: 'Image 4' },
      { lang: 'it', val: 'Immagine 4' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Img4File',
    desc: [
      { lang: 'en', val: 'Image 4 File' },
      { lang: 'de', val: 'Bild 4 Datei' },
      { lang: 'es', val: 'Archivo de Imagen 4' },
      { lang: 'fr', val: 'Fichier Image 4' },
      { lang: 'it', val: 'File Immagine 4' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
];