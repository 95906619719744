import { ILangWidget } from 'src/types/product';

export const LangCertificates: ILangWidget = [
  {
    feId: 'Custom_Ids',
    desc: [
      { lang: 'en', val: 'Custom_Ids' },
      { lang: 'de', val: 'Custom_Ids' },
      { lang: 'es', val: 'Custom_Ids' },
      { lang: 'fr', val: 'Custom_Ids' },
      { lang: 'it', val: 'Custom_Ids' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Naturland_CertName',
    desc: [
      { lang: 'en', val: 'Certificate Name' },
      { lang: 'de', val: 'Zertifikat Name' },
      { lang: 'es', val: 'Certificate Name' },
      { lang: 'fr', val: 'Certificate Name' },
      { lang: 'it', val: 'Certificate Name' },
    ],
    value: [
      { lang: 'en', val: ['Naturland certified'] },
      { lang: 'de', val: ['Naturland zertifiziert'] },
      { lang: 'es', val: ['Naturland certified'] },
      { lang: 'fr', val: ['Naturland certified'] },
      { lang: 'it', val: ['Naturland certified'] },
    ],
  },
  {
    feId: 'Naturland_CertYear',
    desc: [
      { lang: 'en', val: 'Certificate Year' },
      { lang: 'de', val: 'Zertifikat Jahr' },
      { lang: 'es', val: 'Certificate Year' },
      { lang: 'fr', val: 'Certificate Year' },
      { lang: 'it', val: 'Certificate Year' },
    ],
    value: [
      { lang: 'en', val: ['2024'] },
      { lang: 'de', val: ['2024'] },
      { lang: 'es', val: ['2024'] },
      { lang: 'fr', val: ['2024'] },
      { lang: 'it', val: ['2024'] },
    ],
  },
  {
    feId: 'Naturland_ExDate',
    desc: [
      { lang: 'en', val: 'Expiry Date' },
      { lang: 'de', val: 'Gültig bis' },
      { lang: 'es', val: 'Expiry Date' },
      { lang: 'fr', val: 'Expiry Date' },
      { lang: 'it', val: 'Expiry Date' },
    ],
    value: [
      { lang: 'en', val: ['31.12.2024'] },
      { lang: 'de', val: ['31.12.2024'] },
      { lang: 'es', val: ['31.12.2024'] },
      { lang: 'fr', val: ['31.12.2024'] },
      { lang: 'it', val: ['31.12.2024'] },
    ],
  },
  {
    feId: 'Naturland_CertBody',
    desc: [
      { lang: 'en', val: 'Certificate Body' },
      { lang: 'de', val: 'Prüfstelle' },
      { lang: 'es', val: 'Certificate Body' },
      { lang: 'fr', val: 'Certificate Body' },
      { lang: 'it', val: 'Certificate Body' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'Naturland_Mail',
    desc: [
      { lang: 'en', val: 'Contact Mail' },
      { lang: 'de', val: 'Kontakt Mail' },
      { lang: 'es', val: 'Contact Mail' },
      { lang: 'fr', val: 'Contact Mail' },
      { lang: 'it', val: 'Contact Mail' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'Naturland_Web',
    desc: [
      { lang: 'en', val: 'Website' },
      { lang: 'de', val: 'Webseite' },
      { lang: 'es', val: 'Website' },
      { lang: 'fr', val: 'Website' },
      { lang: 'it', val: 'Website' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'Naturland_Document',
    desc: [
      { lang: 'en', val: 'Document' },
      { lang: 'de', val: 'Dokument' },
      { lang: 'es', val: 'Document' },
      { lang: 'fr', val: 'Document' },
      { lang: 'it', val: 'Document' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Naturland_DocumentName',
    desc: [
      { lang: 'en', val: 'Document' },
      { lang: 'de', val: 'Dokument' },
      { lang: 'es', val: 'Document' },
      { lang: 'fr', val: 'Document' },
      { lang: 'it', val: 'Document' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'EUBio_CertName',
    desc: [
      { lang: 'en', val: 'Certificate Name' },
      { lang: 'de', val: 'Zertifikat Name' },
      { lang: 'es', val: 'Certificate Name' },
      { lang: 'fr', val: 'Certificate Name' },
      { lang: 'it', val: 'Certificate Name' },
    ],
    value: [
      { lang: 'en', val: ['EU-Bio certified'] },
      { lang: 'de', val: ['EU-Bio zertifiziert'] },
      { lang: 'es', val: ['EU-Bio certified'] },
      { lang: 'fr', val: ['EU-Bio certified'] },
      { lang: 'it', val: ['EU-Bio certified'] },
    ],
  },
  {
    feId: 'EUBio_CertCountry',
    desc: [
      { lang: 'en', val: 'Country of Origin' },
      { lang: 'de', val: 'Herkunftsland' },
      { lang: 'es', val: 'Country of Origin' },
      { lang: 'fr', val: 'Country of Origin' },
      { lang: 'it', val: 'Country of Origin' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'EUBio_CertYear',
    desc: [
      { lang: 'en', val: 'Certificate Year' },
      { lang: 'de', val: 'Zertifikat Jahr' },
      { lang: 'es', val: 'Certificate Year' },
      { lang: 'fr', val: 'Certificate Year' },
      { lang: 'it', val: 'Certificate Year' },
    ],
    value: [
      { lang: 'en', val: ['2024'] },
      { lang: 'de', val: ['2024'] },
      { lang: 'es', val: ['2024'] },
      { lang: 'fr', val: ['2024'] },
      { lang: 'it', val: ['2024'] },
    ],
  },
  {
    feId: 'EUBio_ExDate',
    desc: [
      { lang: 'en', val: 'Expiry Date' },
      { lang: 'de', val: 'Gültig bis' },
      { lang: 'es', val: 'Expiry Date' },
      { lang: 'fr', val: 'Expiry Date' },
      { lang: 'it', val: 'Expiry Date' },
    ],
    value: [
      { lang: 'en', val: ['31.12.2024'] },
      { lang: 'de', val: ['31.12.2024'] },
      { lang: 'es', val: ['31.12.2024'] },
      { lang: 'fr', val: ['31.12.2024'] },
      { lang: 'it', val: ['31.12.2024'] },
    ],
  },
  {
    feId: 'EUBio_CertBody',
    desc: [
      { lang: 'en', val: 'Certificate Body' },
      { lang: 'de', val: 'Prüfstelle' },
      { lang: 'es', val: 'Certificate Body' },
      { lang: 'fr', val: 'Certificate Body' },
      { lang: 'it', val: 'Certificate Body' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'EUBio_Mail',
    desc: [
      { lang: 'en', val: 'Contact Mail' },
      { lang: 'de', val: 'Kontakt Mail' },
      { lang: 'es', val: 'Contact Mail' },
      { lang: 'fr', val: 'Contact Mail' },
      { lang: 'it', val: 'Contact Mail' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'EUBio_Web',
    desc: [
      { lang: 'en', val: 'Website' },
      { lang: 'de', val: 'Webseite' },
      { lang: 'es', val: 'Website' },
      { lang: 'fr', val: 'Website' },
      { lang: 'it', val: 'Website' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'EUBio_CertNumber',
    desc: [
      { lang: 'en', val: 'Certificate Number' },
      { lang: 'de', val: 'Zertifikat Nummer' },
      { lang: 'es', val: 'Certificate Number' },
      { lang: 'fr', val: 'Certificate Number' },
      { lang: 'it', val: 'Certificate Number' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'EUBio_CertBodyNumber',
    desc: [
      { lang: 'en', val: 'Certificate Body Number' },
      { lang: 'de', val: 'Prüfstellen Nummer' },
      { lang: 'es', val: 'Certificate Body Number' },
      { lang: 'fr', val: 'Certificate Body Number' },
      { lang: 'it', val: 'Certificate Body Number' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
];