
// @mui
import { enUS, deDE, esES, frFR, itIT } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: 'flagpack:de',
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: 'flagpack:es',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: 'flagpack:fr',
  },
  {
    label: 'Italian',
    value: 'it',
    systemValue: itIT,
    icon: 'flagpack:it',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
