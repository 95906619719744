import { ILangWidget } from 'src/types/product';

export const LangHeading: ILangWidget = [
  {
    feId: 'HeadingText',
    desc: [
      { lang: 'en', val: 'Heading' },
      { lang: 'de', val: 'Überschrift' },
      { lang: 'es', val: 'Encabezado' },
      { lang: 'fr', val: 'Titre' },
      { lang: 'it', val: 'Titolo' },
    ],
    value: [
      { lang: 'en', val: ['Heading'] },
      { lang: 'de', val: ['Überschrift'] },
      { lang: 'es', val: ['Encabezado'] },
      { lang: 'fr', val: ['Titre'] },
      { lang: 'it', val: ['Titolo'] },
    ],
  },
];