import { ILangWidget } from 'src/types/product';

export const LangProductInfo: ILangWidget = [
  {
    feId: 'WidgetHeadline_ProductInformation',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Product Information' },
      { lang: 'es', val: 'Información del producto' },
      { lang: 'fr', val: 'Informations sur le produit' },
      { lang: 'it', val: 'Informazioni sul prodotto' },
    ],
    value: [
      { lang: 'en', val: ['Product Information'] },
      { lang: 'de', val: ['Produkt Information'] },
      { lang: 'es', val: ['Información del producto'] },
      { lang: 'fr', val: ['Informations sur le produit'] },
      { lang: 'it', val: ['Informazioni sul prodotto'] },
    ],
  },
  {
    feId: 'ProductInfoDescription',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      { lang: 'en', val: ['Lorem ipsum'] },
      { lang: 'de', val: ['Lorem ipsum'] },
      { lang: 'es', val: ['Lorem ipsum'] },
      { lang: 'fr', val: ['Lorem ipsum'] },
      { lang: 'it', val: ['Lorem ipsum'] },
    ],
  },
  {
    feId: 'ProductInfoBasicInformation',
    desc: [
      { lang: 'en', val: 'Basic Information' },
      { lang: 'de', val: 'Basic Information' },
      { lang: 'es', val: 'Información básica' },
      { lang: 'fr', val: 'Informations de base' },
      { lang: 'it', val: 'Informazioni di base' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Nutrients',
    desc: [
      { lang: 'en', val: 'Nutrients' },
      { lang: 'de', val: 'Nährstoffe' },
      { lang: 'es', val: 'Nutrientes' },
      { lang: 'fr', val: 'Nutriments' },
      { lang: 'it', val: 'Nutrienti' },
    ],
    value: [
      { lang: 'en', val: ['Nutrients'] },
      { lang: 'de', val: ['Nährstoffe'] },
      { lang: 'es', val: ['Nutrientes'] },
      { lang: 'fr', val: ['Nutriments'] },
      { lang: 'it', val: ['Nutrienti'] },
    ],
  },
  {
    feId: 'ProductInfoNutrients',
    desc: [
      { lang: 'en', val: 'Nutrients' },
      { lang: 'de', val: 'Nährstoffe' },
      { lang: 'es', val: 'Nutrientes' },
      { lang: 'fr', val: 'Nutriments' },
      { lang: 'it', val: 'Nutrienti' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Energy', value: 0 },
          { key: 'Fat', value: 0 },
          { key: 'Saturated Fat', value: 0 },
          { key: 'Unsaturated Fat', value: 0 },
          { key: 'Carbohydrates', value: 0 },
          { key: 'Sugar', value: 0 },
          { key: 'Polyhydric Alcohols', value: 0 },
          { key: 'Starch', value: 0 },
          { key: 'Dietary Fiber', value: 0 },
          { key: 'Protein', value: 0 },
          { key: 'Salt', value: 0 },
        ],
      },
      {
        lang: 'de',
        val: [
          { key: 'Energie', value: 0 },
          { key: 'Fett', value: 0 },
          { key: 'Gesättigte Fettsäuren', value: 0 },
          { key: 'Ungesättigte Fettsäuren', value: 0 },
          { key: 'Kohlenhydrate', value: 0 },
          { key: 'Zucker', value: 0 },
          { key: 'Mehrwertige Alkohole', value: 0 },
          { key: 'Stärke', value: 0 },
          { key: 'Ballaststoffe', value: 0 },
          { key: 'Proteine', value: 0 },
          { key: 'Salz', value: 0 },
        ],
      },
      {
        lang: 'es',
        val: [
          { key: 'Energía', value: 0 },
          { key: 'Grasa', value: 0 },
          { key: 'Grasa saturada', value: 0 },
          { key: 'Grasa insaturada', value: 0 },
          { key: 'Carbohidratos', value: 0 },
          { key: 'Azúcar', value: 0 },
          { key: 'Alcoholes polihídricos', value: 0 },
          { key: 'Almidón', value: 0 },
          { key: 'Fibra dietética', value: 0 },
          { key: 'Proteína', value: 0 },
          { key: 'Sal', value: 0 },
        ],
      },
      {
        lang: 'fr',
        val: [
          { key: 'Énergie', value: 0 },
          { key: 'Graisse', value: 0 },
          { key: 'Graisse saturée', value: 0 },
          { key: 'Graisse insaturée', value: 0 },
          { key: 'Glucides', value: 0 },
          { key: 'Sucre', value: 0 },
          { key: 'Alcools polyhydriques', value: 0 },
          { key: 'Amidon', value: 0 },
          { key: 'Fibres alimentaires', value: 0 },
          { key: 'Protéine', value: 0 },
          { key: 'Sel', value: 0 },
        ],
      },
      {
        lang: 'it',
        val: [
          { key: 'Energia', value: 0 },
          { key: 'Grasso', value: 0 },
          { key: 'Grasso saturo', value: 0 },
          { key: 'Grasso insaturo', value: 0 },
          { key: 'Carboidrati', value: 0 },
          { key: 'Zucchero', value: 0 },
          { key: 'Alcoli polidrici', value: 0 },
          { key: 'Amido', value: 0 },
          { key: 'Fibra alimentare', value: 0 },
          { key: 'Proteina', value: 0 },
          { key: 'Sale', value: 0 },
        ],
      },
    ],
  },
  {
    feId: 'ProductInfoCustomFields',
    desc: [
      { lang: 'en', val: 'Custom Fields' },
      { lang: 'de', val: 'Custom Fields' },
      { lang: 'es', val: 'Campos personalizados' },
      { lang: 'fr', val: 'Champs personnalisés' },
      { lang: 'it', val: 'Campi personalizzati' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ServingUnit',
    desc: [
      { lang: 'en', val: 'Serving Unit' },
      { lang: 'de', val: 'Serviereinheit' },
      { lang: 'es', val: 'Unidad de porción' },
      { lang: 'fr', val: 'Unité de portion' },
      { lang: 'it', val: 'Unità di porzione' },
    ],
    value: [
      { lang: 'en', val: ['g'] },
      { lang: 'de', val: ['g'] },
      { lang: 'es', val: ['g'] },
      { lang: 'fr', val: ['g'] },
      { lang: 'it', val: ['g'] },
    ],
  },
  {
    feId: 'ReferenceQuantity',
    desc: [
      { lang: 'en', val: 'Reference Quantity' },
      { lang: 'de', val: 'Reference Quantität' },
      { lang: 'es', val: 'Cantidad de referencia' },
      { lang: 'fr', val: 'Quantité de référence' },
      { lang: 'it', val: 'Quantità di riferimento' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ServingSize',
    desc: [
      { lang: 'en', val: 'Serving Size' },
      { lang: 'de', val: 'Serviergröße' },
      { lang: 'es', val: 'Tamaño de porción' },
      { lang: 'fr', val: 'Taille de portion' },
      { lang: 'it', val: 'Dimensione della porzione' },
    ],
    value: [
      { lang: 'en', val: ['100'] },
      { lang: 'de', val: ['100'] },
      { lang: 'es', val: ['100'] },
      { lang: 'fr', val: ['100'] },
      { lang: 'it', val: ['100'] },
    ],
  },
  {
    feId: 'EnableServingSize',
    desc: [
      { lang: 'en', val: 'Enable Serving Size' },
      { lang: 'de', val: 'Enable Serving Size' },
      { lang: 'es', val: 'Habilitar tamaño de porción' },
      { lang: 'fr', val: 'Activer la taille de portion' },
      { lang: 'it', val: 'Abilita dimensione porzione' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
      { lang: 'es', val: ['X'] },
      { lang: 'fr', val: ['X'] },
      { lang: 'it', val: ['X'] },
    ],
  },
  {
    feId: 'WidgetHeadline_Ingredients',
    desc: [
      { lang: 'en', val: 'Ingredients & Allergens' },
      { lang: 'de', val: 'Inhaltsstoffe & Allergene' },
      { lang: 'es', val: 'Ingredientes y alérgenos' },
      { lang: 'fr', val: 'Ingrédients & allergènes' },
      { lang: 'it', val: 'Ingredienti e allergeni' },
    ],
    value: [
      { lang: 'en', val: ['Ingredients & Allergens'] },
      { lang: 'de', val: ['Inhaltsstoffe & Allergene'] },
      { lang: 'es', val: ['Ingredientes y alérgenos'] },
      { lang: 'fr', val: ['Ingrédients & allergènes'] },
      { lang: 'it', val: ['Ingredienti e allergeni'] },
    ],
  },
  {
    feId: 'ProductInfoIngredients',
    desc: [
      { lang: 'en', val: 'Ingredients' },
      { lang: 'de', val: 'Inhaltsstoffe' },
      { lang: 'es', val: 'Ingredientes' },
      { lang: 'fr', val: 'Ingrédients' },
      { lang: 'it', val: 'Ingredienti' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ProductInfoAllergens',
    desc: [
      { lang: 'en', val: 'Allergens' },
      { lang: 'de', val: 'Allergene' },
      { lang: 'es', val: 'Alérgenos' },
      { lang: 'fr', val: 'Allergènes' },
      { lang: 'it', val: 'Allergeni' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ProductInfoNotesAndWarnings',
    desc: [
      { lang: 'en', val: 'Notes and Warnings' },
      { lang: 'de', val: 'Notizen und Warnungen' },
      { lang: 'es', val: 'Notas y advertencias' },
      { lang: 'fr', val: 'Notes et avertissements' },
      { lang: 'it', val: 'Note e avvertenze' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Distributor',
    desc: [
      { lang: 'en', val: 'Distributor' },
      { lang: 'de', val: 'Distributor' },
      { lang: 'es', val: 'Distribuidor' },
      { lang: 'fr', val: 'Distributeur' },
      { lang: 'it', val: 'Distributore' },
    ],
    value: [
      { lang: 'en', val: ['Distributor'] },
      { lang: 'de', val: ['Distributor'] },
      { lang: 'es', val: ['Distribuidor'] },
      { lang: 'fr', val: ['Distributeur'] },
      { lang: 'it', val: ['Distributore'] },
    ],
  },
  {
    feId: 'ProductInfoResponsibleDistributor',
    desc: [
      { lang: 'en', val: 'Responsible Distributor' },
      { lang: 'de', val: 'Verantwortlicher Distributor' },
      { lang: 'es', val: 'Distribuidor responsable' },
      { lang: 'fr', val: 'Distributeur responsable' },
      { lang: 'it', val: 'Distributore responsabile' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'PostalCode',
    desc: [
      { lang: 'en', val: 'Postal Code' },
      { lang: 'de', val: 'Postleitzahl' },
      { lang: 'es', val: 'Código postal' },
      { lang: 'fr', val: 'Code postal' },
      { lang: 'it', val: 'Codice postale' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'City',
    desc: [
      { lang: 'en', val: 'City' },
      { lang: 'de', val: 'Stadt' },
      { lang: 'es', val: 'Ciudad' },
      { lang: 'fr', val: 'Ville' },
      { lang: 'it', val: 'Città' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Street',
    desc: [
      { lang: 'en', val: 'Street' },
      { lang: 'de', val: 'Straße' },
      { lang: 'es', val: 'Calle' },
      { lang: 'fr', val: 'Rue' },
      { lang: 'it', val: 'Via' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Link',
    desc: [
      { lang: 'en', val: 'Link' },
      { lang: 'de', val: 'Link' },
      { lang: 'es', val: 'Enlace' },
      { lang: 'fr', val: 'Lien' },
      { lang: 'it', val: 'Link' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'Email',
    desc: [
      { lang: 'en', val: 'Email' },
      { lang: 'de', val: 'Email' },
      { lang: 'es', val: 'Correo electrónico' },
      { lang: 'fr', val: 'Email' },
      { lang: 'it', val: 'Email' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ProducedIn',
    desc: [
      { lang: 'en', val: 'Produced in' },
      { lang: 'de', val: 'Hergestellt in' },
      { lang: 'es', val: 'Producido en' },
      { lang: 'fr', val: 'Produit en' },
      { lang: 'it', val: 'Prodotto in' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Storage',
    desc: [
      { lang: 'en', val: 'Storage Instructions' },
      { lang: 'de', val: 'Aufbewahrungshinweise' },
      { lang: 'es', val: 'Instrucciones de almacenamiento' },
      { lang: 'fr', val: 'Instructions de stockage' },
      { lang: 'it', val: 'Istruzioni di conservazione' },
    ],
    value: [
      { lang: 'en', val: ['Storage Instructions'] },
      { lang: 'de', val: ['Aufbewahrungshinweise'] },
      { lang: 'es', val: ['Instrucciones de almacenamiento'] },
      { lang: 'fr', val: ['Instructions de stockage'] },
      { lang: 'it', val: ['Istruzioni di conservazione'] },
    ],
  },
  {
    feId: 'ProductInfoStorageFields',
    desc: [
      { lang: 'en', val: 'Storage Fields' },
      { lang: 'de', val: 'Aufbewahrungsfelder' },
      { lang: 'es', val: 'Campos de almacenamiento' },
      { lang: 'fr', val: 'Champs de stockage' },
      { lang: 'it', val: 'Campi di conservazione' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Temperature', value: '' },
          { key: 'Humidity', value: '' },
        ],
      },
      {
        lang: 'de',
        val: [
          { key: 'Temperatur', value: '' },
          { key: 'Feuchtigkeit', value: '' },
        ],
      },
      {
        lang: 'es',
        val: [
          { key: 'Temperatura', value: '' },
          { key: 'Humedad', value: '' },
        ],
      },
      {
        lang: 'fr',
        val: [
          { key: 'Température', value: '' },
          { key: 'Humidité', value: '' },
        ],
      },
      {
        lang: 'it',
        val: [
          { key: 'Temperatura', value: '' },
          { key: 'Umidità', value: '' },
        ],
      },
    ],
  },
];