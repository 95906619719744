import { ILangWidget } from 'src/types/product';

export const LangOrigin: ILangWidget = [
  {
    feId: 'WidgetHeadline_Origin',
    desc: [
      { lang: 'en', val: 'Origin' },
      { lang: 'de', val: 'Herkunft' },
      { lang: 'es', val: 'Origen' },
      { lang: 'fr', val: 'Origine' },
      { lang: 'it', val: 'Origine' },
    ],
    value: [
      { lang: 'en', val: ['Where does it come from?'] },
      { lang: 'de', val: ["Wo kommt's her?"] },
      { lang: 'es', val: ['¿De dónde viene?'] },
      { lang: 'fr', val: ["D'où vient-il ?"] },
      { lang: 'it', val: ['Da dove viene?'] },
    ],
  },
  {
    feId: 'ShowMap',
    desc: [
      { lang: 'en', val: 'Show Map' },
      { lang: 'de', val: 'Karte einblenden' },
      { lang: 'es', val: 'Mostrar mapa' },
      { lang: 'fr', val: 'Afficher la carte' },
      { lang: 'it', val: 'Mostra mappa' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
      { lang: 'es', val: ['X'] },
      { lang: 'fr', val: ['X'] },
      { lang: 'it', val: ['X'] },
    ],
  },
  {
    feId: 'WidgetHeadline_Production',
    desc: [
      { lang: 'en', val: 'Production' },
      { lang: 'de', val: 'Herstellung' },
      { lang: 'es', val: 'Producción' },
      { lang: 'fr', val: 'Production' },
      { lang: 'it', val: 'Produzione' },
    ],
    value: [
      { lang: 'en', val: ['Production'] },
      { lang: 'de', val: ['Herstellung'] },
      { lang: 'es', val: ['Producción'] },
      { lang: 'fr', val: ['Production'] },
      { lang: 'it', val: ['Produzione'] },
    ],
  },
  {
    feId: 'WidgetDescription_Production',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          'Our beers are brewed in a traditional brewery in Augsburg, which preserves the charm of the art of craft brewing with its stylish brewing kettle.',
        ],
      },
      {
        lang: 'de',
        val: [
          'Unsere Biere werden in einem traditionsreichen Brauhaus in Augsburg gebraut, das mit seinem stilvollen Braukessel den Charme handwerklicher Braukunst bewahrt.',
        ],
      },
      {
        lang: 'es',
        val: [
          'Nuestras cervezas se elaboran en una cervecería tradicional en Augsburgo, que conserva el encanto del arte de la elaboración artesanal con su elegante caldera de cerveza.',
        ],
      },
      {
        lang: 'fr',
        val: [
          'Nos bières sont brassées dans une brasserie traditionnelle à Augsbourg, préservant le charme de l’art de la bière artisanale avec sa chaudière élégante.',
        ],
      },
      {
        lang: 'it',
        val: [
          'Le nostre birre sono prodotte in un birrificio tradizionale ad Augsburg, che preserva il fascino dell’arte della birrificazione artigianale con la sua elegante caldaia.',
        ],
      },
    ],
  },
  {
    feId: 'ProductionPlaces',
    desc: [
      { lang: 'en', val: 'Production places' },
      { lang: 'de', val: 'Herstellungsorte' },
      { lang: 'es', val: 'Lugares de producción' },
      { lang: 'fr', val: 'Lieux de production' },
      { lang: 'it', val: 'Luoghi di produzione' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'WidgetHeadline_Ressources',
    desc: [
      { lang: 'en', val: 'Raw materials' },
      { lang: 'de', val: 'Rohstoffe' },
      { lang: 'es', val: 'Materias primas' },
      { lang: 'fr', val: 'Matières premières' },
      { lang: 'it', val: 'Materie prime' },
    ],
    value: [
      { lang: 'en', val: ['Raw materials'] },
      { lang: 'de', val: ['Rohstoffe'] },
      { lang: 'es', val: ['Materias primas'] },
      { lang: 'fr', val: ['Matières premières'] },
      { lang: 'it', val: ['Materie prime'] },
    ],
  },
  {
    feId: 'WidgetDescription_Ressources',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      {
        lang: 'en',
        val: ['All our raw materials come from certified-organic cultivation and processing.'],
      },
      {
        lang: 'de',
        val: ['Alle unsere Rohstoffe kommen aus zertifiziert-ökologischem Anbau und Verarbeitung.'],
      },
      {
        lang: 'es',
        val: [''],
      },
      {
        lang: 'fr',
        val: [''],
      },
      {
        lang: 'it',
        val: [
          'Tutte le nostre materie prime provengono da coltivazioni e lavorazioni biologiche certificate - senza pesticidi e da un raggio massimo di 150 km dal birrificio.',
        ],
      },
    ],
  },
  {
    feId: 'RessourcesPlaces',
    desc: [
      { lang: 'en', val: 'Raw material places' },
      { lang: 'de', val: 'Rohstoffherkunft' },
      { lang: 'es', val: 'Origen de las materias primas' },
      { lang: 'fr', val: 'Origine des matières premières' },
      { lang: 'it', val: 'Origine delle materie prime' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'WidgetHeadline_Packaging',
    desc: [
      { lang: 'en', val: 'Packaging' },
      { lang: 'de', val: 'Verpackung' },
      { lang: 'es', val: 'Embalaje' },
      { lang: 'fr', val: 'Emballage' },
      { lang: 'it', val: 'Confezionamento' },
    ],
    value: [
      { lang: 'en', val: ['Packaging'] },
      { lang: 'de', val: ['Verpackung'] },
      { lang: 'es', val: ['Embalaje'] },
      { lang: 'fr', val: ['Emballage'] },
      { lang: 'it', val: ['Confezionamento'] },
    ],
  },
  {
    feId: 'WidgetDescription_Packaging',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      {
        lang: 'en',
        val: [''],
      },
      {
        lang: 'de',
        val: [''],
      },
      {
        lang: 'es',
        val: [''],
      },
      {
        lang: 'fr',
        val: [''],
      },
      {
        lang: 'it',
        val: [''],
      },
    ],
  },
  {
    feId: 'PackagingPlaces',
    desc: [
      { lang: 'en', val: 'Packaging places' },
      { lang: 'de', val: 'Verpackungsherkunft' },
      { lang: 'es', val: 'Lugares de embalaje' },
      { lang: 'fr', val: "Lieux d'emballage" },
      { lang: 'it', val: 'Luoghi di confezionamento' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
];
