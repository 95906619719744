import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

function LogoNormalSlogan({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 3010 1020"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2470.18 324.005C2495.26 324.005 2515.06 332.792 2529.54 350.381C2544.03 367.97 2549.09 389.15 2544.73 413.912C2540.3 438.991 2527.75 460.327 2507.06 477.916C2486.37 495.505 2463.64 504.311 2438.88 504.311C2413.48 504.311 2393.68 495.51 2379.52 477.916C2365.08 460.011 2360.11 438.344 2364.59 412.931C2368.84 388.829 2381.39 367.97 2402.25 350.377C2423.11 332.788 2445.75 324.005 2470.18 324.005Z"
          fill="#212B36"
        />
        <path
          d="M769.731 991C757.318 991 747.903 979.811 750.025 967.581L805.314 648.905C806.924 639.625 814.792 632.725 824.203 632.341L953.041 627.075C965.78 626.554 975.747 637.926 973.561 650.487L917.196 974.428C915.529 984.007 907.215 991 897.492 991H769.731Z"
          fill="#212B36"
        />
        <path
          d="M1050.98 991C1038.54 991 1029.11 979.758 1031.29 967.505L1108.02 535.082C1109.66 525.836 1117.52 518.977 1126.9 518.593L1255.35 513.343C1268.23 512.816 1278.24 524.437 1275.81 537.098L1270.52 564.614C1269.89 567.937 1274.8 569.865 1276.76 567.101C1291.46 546.3 1309.23 530.504 1330.06 519.712C1355.96 506.454 1382.78 499.825 1410.53 499.825C1420.09 499.825 1429.03 500.441 1437.36 501.675C1438.77 501.876 1440.17 502.086 1441.55 502.304C1452.73 504.063 1459.36 515.351 1456.33 526.259L1419.74 658.22C1416.55 669.701 1404.09 675.806 1392.59 672.715C1388.19 671.535 1383.69 670.484 1379.08 669.562C1366.75 666.787 1354.42 665.4 1342.08 665.4C1316.49 665.4 1293.98 672.491 1274.56 686.675C1255.44 700.55 1243.11 722.904 1237.56 753.737L1198.4 974.493C1196.7 984.042 1188.4 991 1178.7 991H1050.98Z"
          fill="#212B36"
        />
        <path
          d="M1696.41 1004.87C1645.22 1004.87 1600.82 995.316 1563.21 976.2C1525.9 956.775 1496.92 929.95 1476.26 895.725C1455.91 861.192 1445.73 821.417 1445.73 776.4C1445.73 736.316 1452.67 699.625 1466.54 666.325C1480.73 632.716 1500.46 603.579 1525.74 578.912C1551.03 554.246 1580.78 535.129 1615.01 521.562C1649.23 507.687 1686.39 500.75 1726.47 500.75C1770.87 500.75 1809.57 508.458 1842.56 523.875C1875.86 539.292 1902.37 560.721 1922.11 588.162C1942.15 615.296 1954.64 646.9 1959.57 682.975C1963.64 712.72 1961.73 744.142 1953.85 777.24C1950.8 790.047 1939.03 798.6 1925.86 798.6H1598.07C1596.43 798.6 1595.09 799.913 1595.12 801.549C1595.44 817.819 1600.83 831.482 1611.31 842.537C1622.41 853.946 1637.21 862.579 1655.71 868.437C1674.21 874.296 1694.56 877.225 1716.76 877.225C1742.66 877.225 1767.48 874.296 1791.22 868.437C1807.16 864.505 1823.02 859.184 1838.82 852.473C1850.79 847.389 1864.85 853.865 1867.82 866.524L1884.63 938.223C1887.65 951.149 1881.88 964.671 1869.73 970.035C1859.1 974.73 1847.27 979.098 1834.23 983.137C1812.34 989.921 1789.37 995.162 1765.32 998.862C1741.27 1002.87 1718.3 1004.87 1696.41 1004.87ZM1621.91 682.974C1621.38 685.382 1623.26 687.6 1625.73 687.6H1797.91C1800.1 687.6 1801.89 685.84 1801.83 683.653C1801.22 663.117 1793.52 647.629 1778.73 637.187C1763.32 625.779 1742.97 620.075 1717.68 620.075C1692.09 620.075 1670.35 626.241 1652.47 638.575C1636.23 649.688 1626.04 664.487 1621.91 682.974Z"
          fill="#212B36"
        />
        <path
          d="M2016.2 991C2003.76 991 1994.33 979.754 1996.51 967.5L2106.99 345.958C2108.64 336.695 2116.52 329.832 2125.92 329.473L2254.74 324.559C2267.49 324.072 2277.43 335.489 2275.19 348.051L2163.62 974.507C2161.92 984.049 2153.63 991 2143.93 991H2016.2Z"
          fill="#212B36"
        />
        <path
          d="M2759.07 1003.95C2716.52 1003.95 2675.21 998.091 2635.12 986.375C2600.82 976.083 2570.46 961.048 2544.06 941.27C2536.34 935.486 2534.56 924.811 2539.39 916.457L2584.08 839.058C2589.91 828.962 2603.08 826.018 2613.06 832.037C2634.32 844.853 2656.78 855.445 2680.45 863.812C2711.28 874.604 2740.26 880 2767.4 880C2787.75 880 2803.16 877.071 2813.65 871.212C2824.44 865.046 2829.84 856.567 2829.84 845.775C2829.84 838.375 2824.9 832.825 2815.04 829.125C2805.48 825.116 2785.74 819.875 2755.84 813.4C2697.87 801.375 2654.86 783.8 2626.8 760.675C2599.05 737.55 2585.17 707.179 2585.17 669.562C2585.17 633.796 2594.73 602.962 2613.85 577.062C2633.27 551.162 2659.17 531.275 2691.55 517.4C2724.23 503.216 2760.61 496.125 2800.7 496.125C2842.94 496.125 2882.41 502.291 2919.1 514.625C2949.51 524.847 2975.47 537.929 2996.99 553.871C3004.64 559.539 3006.36 570.064 3001.66 578.341L2958.35 654.532C2952.14 665.449 2937.51 667.981 2927.01 661.096C2919.27 656.021 2910.93 651.289 2901.99 646.9C2885.03 638.575 2867.3 632.1 2848.8 627.475C2830.61 622.541 2813.03 620.075 2796.07 620.075C2778.19 620.075 2763.85 623.004 2753.06 628.862C2742.27 634.412 2736.87 641.966 2736.87 651.525C2736.87 659.233 2741.65 665.4 2751.21 670.025C2760.77 674.342 2779.73 680.2 2808.1 687.6C2869.15 703.016 2913.09 721.516 2939.91 743.1C2966.74 764.375 2980.15 792.433 2980.15 827.275C2980.15 863.042 2970.59 894.183 2951.47 920.7C2932.36 947.216 2906.15 967.721 2872.85 982.212C2839.55 996.704 2801.62 1003.95 2759.07 1003.95Z"
          fill="#212B36"
        />
        <path
          d="M2298.5 991C2286.09 991 2276.67 979.811 2278.79 967.581L2334.08 648.905C2335.69 639.625 2343.56 632.725 2352.97 632.341L2481.81 627.075C2494.55 626.554 2504.52 637.926 2502.33 650.487L2445.97 974.428C2444.3 984.007 2435.98 991 2426.26 991H2298.5Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M409.353 39.3637C409.353 39.3637 552.188 -19.3438 742.912 6.63234C742.912 6.63234 906.05 95.8348 978.749 398.92L1025 388.324C1007.88 420.199 971.094 475.977 934.708 521.523C898.55 571.865 877.611 585.679 819.407 558.469C656.964 482.531 819.407 558.469 656.964 482.531C656.968 482.529 704.992 469.419 704.992 469.419C704.992 469.419 693.829 210.422 409.353 39.3637Z"
          fill="url(#paint0_linear_5512_273)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M589.596 259.777C383.061 354.755 285.117 464.675 248.5 694C162 667 32.2038 565.445 7.62016 519.334C40.0083 380.483 120.571 158.209 369.926 58.1759C413.53 76.941 534.948 165.455 589.596 259.777Z"
          fill="#212B36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 566.918C186.678 771 460.221 794.182 674.576 724.697C683.832 721.697 718.415 710.122 721.114 731.01C713.047 809.256 689.725 919.532 659.889 977.351C653.746 989.255 644.784 990.563 631 993.63C365.9 1052.62 162.118 992.826 58.1383 893.705C12.1522 779.078 3.69566 664.33 0 566.918Z"
          fill="url(#paint1_linear_5512_273)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5512_273"
            x1="766.977"
            y1="-180.009"
            x2="-0.00205134"
            y2="1364.97"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAB00" />
            <stop offset="0.136705" stopColor="#80AB2B" />
            <stop offset="0.3" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5512_273"
            x1="846.981"
            y1="-138.375"
            x2="-103.681"
            y2="1320.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0187618" stopColor="#FFAB00" />
            <stop offset="0.115031" stopColor="#80AB2B" />
            <stop offset="0.225978" stopColor="#00AB55" />
            <stop offset="0.465196" stopColor="#00AB55" />
            <stop offset="0.902096" stopColor="#00B8D9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(LogoNormalSlogan);
