//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ShowcaseCarousel: IWidget = {
  id: uuid(),
  name: 'ShowcaseCarousel',
  order: 0,
  drop: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'carousel',
    name: 'Carousel',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        'Image1': {
          feId: 'Image1',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Image1'),
          value: getValue('Image1'),
        },
        'Img1File': {
          feId: 'Img1File',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Img1File'),
          value: getValue('Img1File'),
        },
        'Image2': {
          feId: 'Image2',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Image2'),
          value: getValue('Image2'),
        },
        'Img2File': {
          feId: 'Img2File',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Img2File'),
          value: getValue('Img2File'),
        },
        'Image3': {
          feId: 'Image3',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Image3'),
          value: getValue('Image3'),
        },
        'Img3File': {
          feId: 'Img3File',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Img3File'),
          value: getValue('Img3File'),
        },
        'Image4': {
          feId: 'Image4',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Image4'),
          value: getValue('Image4'),
        },
        'Img4File': {
          feId: 'Img4File',
          template: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Img4File'),
          value: getValue('Img4File'),
        },
      },
    },
  ],
  style: {
    'Ratio': {
      name: 'Ratio',
      value: ['1/1'],
    },
  },
};
