import { ILangWidget } from 'src/types/product';

export const LangShowcaseImage: ILangWidget = [
  {
    feId: 'Ratio',
    desc: [
      { lang: 'en', val: 'Ratio' },
      { lang: 'de', val: 'Verhältnis' },
      { lang: 'es', val: 'Relación' },
      { lang: 'fr', val: 'Ratio' },
      { lang: 'it', val: 'Rapporto' },
    ],
    value: [
      { lang: 'en', val: ['1/1'] },
      { lang: 'de', val: ['1/1'] },
      { lang: 'es', val: ['1/1'] },
      { lang: 'fr', val: ['1/1'] },
      { lang: 'it', val: ['1/1'] },
    ],
  },
  {
    feId: 'Image',
    desc: [
      { lang: 'en', val: 'Image' },
      { lang: 'de', val: 'Bild' },
      { lang: 'es', val: 'Imagen' },
      { lang: 'fr', val: 'Image' },
      { lang: 'it', val: 'Immagine' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'ImgFile',
    desc: [
      { lang: 'en', val: 'Image File' },
      { lang: 'de', val: 'Bilddatei' },
      { lang: 'es', val: 'Archivo de Imagen' },
      { lang: 'fr', val: 'Fichier d’image' },
      { lang: 'it', val: 'File immagine' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
];