import { ILangWidget } from 'src/types/product';

export const LangCustomWidget: ILangWidget = [
  {
    feId: 'WidgetHeadline_CustomWidget',
    desc: [
      { lang: 'en', val: 'Custom Headline' },
      { lang: 'de', val: 'Custom Headline' },
      { lang: 'es', val: 'Titular Personalizado' },
      { lang: 'fr', val: 'Titre Personnalisé' },
      { lang: 'it', val: 'Titolo Personalizzato' },
    ],
    value: [
      { lang: 'en', val: ['Custom Headline'] },
      { lang: 'de', val: ['Custom Headline'] },
      { lang: 'es', val: ['Titular Personalizado'] },
      { lang: 'fr', val: ['Titre Personnalisé'] },
      { lang: 'it', val: ['Titolo Personalizzato'] },
    ],
  },
  {
    feId: 'WidgetIcon_CustomWidget',
    desc: [
      { lang: 'en', val: 'Custom Icon' },
      { lang: 'de', val: 'Custom Icon' },
      { lang: 'es', val: 'Icono Personalizado' },
      { lang: 'fr', val: 'Icône Personnalisée' },
      { lang: 'it', val: 'Icona Personalizzata' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'DropId_CustomWidget',
    desc: [
      { lang: 'en', val: '' },
      { lang: 'de', val: '' },
      { lang: 'es', val: '' },
      { lang: 'fr', val: '' },
      { lang: 'it', val: '' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
];