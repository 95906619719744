import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import { LicenseInfo } from '@mui/x-license';
// import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MATOMO_URL, SENTRY_URL } from './config-global';
import './suppressLogs'; // Import the suppression utility
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const instance = createInstance({
  urlBase: MATOMO_URL || '',
  siteId: 1,
});

LicenseInfo.setLicenseKey('0007166a77dae692bee07dd9a6cb91c1Tz0xMDg4MTcsRT0xNzcyNDA5NTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

// Sentry.init({
//   dsn: SENTRY_URL || '',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   tracePropagationTargets: ['https://clis.app', 'https://clfy.app'],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <MatomoProvider value={instance}>
          <App />
        </MatomoProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
