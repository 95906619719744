// hooks
import { AuthUserType } from 'src/auth/types';
import { firestore } from 'src/firebase/firebaseInit';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// components
import { useSnackbar } from 'src/components/snackbar';
import { useGetCompanys } from 'src/api/company';
import { useCallback, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { ICompany } from 'src/types/company';
import { doc, updateDoc } from 'firebase/firestore';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentUser?: AuthUserType;
};

export default function CompanyQuickEditForm({ currentUser, open, onClose }: Props) {
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const { companys, companysLoading } = useGetCompanys(open, user);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSelectCompany = async (company: ICompany) => {
    setSelectedCompany(company);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      // firestore
      //   .collection('users')
      //   .doc(currentUser?.uid)
      //   .update({ company: selectedCompany?.id })
      const docu = doc(firestore, 'users', currentUser?.uid);
      updateDoc(docu, { company: selectedCompany?.id }).then(() => {
        // Update successful
        onClose();
        enqueueSnackbar('Update success!');
        setTimeout(() => {
          window.location.reload();
        }, 30); // Adjust the delay as needed
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      {!companysLoading && (
        <>
          <DialogTitle>Companys</DialogTitle>
          <DialogContent sx={{ px: 1 }}>
            <div>
              {companys.map((company) => {
                const address = `${company?.street}, ${company?.plz} ${company?.city}`;
                // const adminUser = companyUsers.find((user) => user.role === 'admin');
                // const adminUserDisplayName = companyUsers.find((user) => user.id === adminUser?.id)
                //   ?.displayName;
                return (
                  <Button
                    fullWidth
                    key={company?.id}
                    onClick={() => {
                      handleSelectCompany(company);
                    }}
                    onMouseEnter={() => setSelectedCompany(company)}
                    onMouseLeave={() => setSelectedCompany(null)}
                    sx={{
                      backgroundColor:
                        selectedCompany?.id === company?.id
                          ? (theme) => theme.palette.grey[200]
                          : 'transparent',
                      border: user?.company === company?.id ? 2 : 0,
                      borderColor:
                        user?.company === company?.id
                          ? (theme) => theme.palette.primary.main
                          : 'transparent',
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="column"
                      sx={{ px: 1.5, py: 1, alignItems: 'flex-start' }}
                    >
                      <Typography variant="subtitle2">{company?.name}</Typography>
                      {/* <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.primary.main }}
                      >
                        {adminUserDisplayName}
                      </Typography> */}
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {address}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        Telefonnummer
                      </Typography>
                    </Stack>
                  </Button>
                );
              })}
            </div>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              {/* <RHFRadioGroup
                name="company"
                options={companys.map((company) => ({
                  label: company?.name,
                  value: company?.id,
                }))}
              /> */}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
