//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Certificates: IWidget = {
  id: uuid(),
  name: 'Certificates',
  order: 0,
  drop: 0,
  category: 'Basic',
  open: false,
  label: {
    id: 'certificates',
    name: 'Certificates',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
      },
    },
    {
      id: 'Naturland',
      open: false,
      show: false,
      attributes: {
        'Naturland_CertName': {
          feId: 'Naturland_CertName',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_CertName'),
          value: getValue('Naturland_CertName'),
        },
        'Naturland_CertBody': {
          feId: 'Naturland_CertBody',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_CertBody'),
          value: getValue('Naturland_CertBody'),
        },
        'Naturland_Mail': {
          feId: 'Naturland_Mail',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_Mail'),
          value: getValue('Naturland_Mail'),
        },
        'Naturland_Web': {
          feId: 'Naturland_Web',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_Web'),
          value: getValue('Naturland_Web'),
        },
        'Naturland_Document': {
          feId: 'Naturland_Document',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_Document'),
          value: getValue('Naturland_Document'),
        },
        'Naturland_DocumentName': {
          feId: 'Naturland_DocumentName',
          template: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Naturland_DocumentName'),
          value: getValue('Naturland_DocumentName'),
        },
      },
    },
    {
      id: 'EU-Bio',
      open: false,
      show: true,
      attributes: {
        'EUBio_CertName': {
          feId: 'EUBio_CertName',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_CertName'),
          value: getValue('EUBio_CertName'),
        },
        'EUBio_CertCountry': {
          feId: 'EUBio_CertCountry',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_CertCountry'),
          value: getValue('EUBio_CertCountry'),
        },
        'EUBio_CertBody': {
          feId: 'EUBio_CertBody',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_CertBody'),
          value: getValue('EUBio_CertBody'),
        },
        'EUBio_Mail': {
          feId: 'EUBio_Mail',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_Mail'),
          value: getValue('EUBio_Mail'),
        },
        'EUBio_CertNumber': {
          feId: 'EUBio_CertNumber',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_CertNumber'),
          value: getValue('EUBio_CertNumber'),
        },
        'EUBio_CertBodyNumber': {
          feId: 'EUBio_CertBodyNumber',
          template: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('EUBio_CertBodyNumber'),
          value: getValue('EUBio_CertBodyNumber'),
        },
      },
    },
    {
      id: 'Custom',
      open: false,
      show: true,
      attributes: {
        'Custom_Ids': {
          feId: 'Custom_Ids',
          template: true,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Custom_Ids'),
          value: getValue('Custom_Ids'),
        },
      },
    },
  ],
  style: {},
};
