import { ILangWidget } from 'src/types/product';

export const LangSustainability: ILangWidget = [
  {
    feId: 'WidgetHeadline_Sustainability',
    desc: [
      { lang: 'en', val: 'Sustainability' },
      { lang: 'de', val: 'Nachhaltigkeit' },
      { lang: 'es', val: 'Sostenibilidad' },
      { lang: 'fr', val: 'Durabilité' },
      { lang: 'it', val: 'Sostenibilità' },
    ],
    value: [
      { lang: 'en', val: ['Sustainability'] },
      { lang: 'de', val: ['Nachhaltigkeit'] },
      { lang: 'es', val: ['Sostenibilidad'] },
      { lang: 'fr', val: ['Durabilité'] },
      { lang: 'it', val: ['Sostenibilità'] },
    ],
  },
  {
    feId: 'SustainabilityDescription',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      { lang: 'en', val: ['Description'] },
      { lang: 'de', val: ['Beschreibung'] },
      { lang: 'es', val: ['Descripción'] },
      { lang: 'fr', val: ['Description'] },
      { lang: 'it', val: ['Descrizione'] },
    ],
  },
  {
    feId: 'WidgetHeadline_Initiatives',
    desc: [
      { lang: 'en', val: 'Sustainability Initiatives' },
      { lang: 'de', val: 'Nachhaltigkeitsinitiativen' },
      { lang: 'es', val: 'Iniciativas de sostenibilidad' },
      { lang: 'fr', val: 'Initiatives de durabilité' },
      { lang: 'it', val: 'Iniziative di sostenibilità' },
    ],
    value: [
      { lang: 'en', val: ['Sustainability Initiatives'] },
      { lang: 'de', val: ['Nachhaltigkeitsinitiativen'] },
      { lang: 'es', val: ['Iniciativas de sostenibilidad'] },
      { lang: 'fr', val: ['Initiatives de durabilité'] },
      { lang: 'it', val: ['Iniziative di sostenibilità'] },
    ],
  },
  {
    feId: 'SubheadlineInitiatives',
    desc: [
      { lang: 'en', val: 'Subheadline Initiatives' },
      { lang: 'de', val: 'Subheadline Initiative' },
      { lang: 'es', val: 'Subtítulo de iniciativas' },
      { lang: 'fr', val: 'Sous-titre des initiatives' },
      { lang: 'it', val: 'Sottotitolo iniziative' },
    ],
    value: [
      { lang: 'en', val: ['Fair prices for our farmers'] },
      { lang: 'de', val: ['Faire Preise für unsere Landwirte'] },
      { lang: 'es', val: ['Precios justos para nuestros agricultores'] },
      { lang: 'fr', val: ['Prix équitables pour nos agriculteurs'] },
      { lang: 'it', val: ['Prezzi equi per i nostri agricoltori'] },
    ],
  },
  {
    feId: 'InitiativePicture',
    desc: [
      { lang: 'en', val: 'Initiative Picture' },
      { lang: 'de', val: 'Initiative Bild' },
      { lang: 'es', val: 'Imagen de la iniciativa' },
      { lang: 'fr', val: 'Image de l’initiative' },
      { lang: 'it', val: 'Immagine dell’iniziativa' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'InitiativeFilename',
    desc: [
      { lang: 'en', val: 'Initiative Filename' },
      { lang: 'de', val: 'Initiative Datei' },
      { lang: 'es', val: 'Nombre de archivo de la iniciativa' },
      { lang: 'fr', val: 'Nom de fichier de l’initiative' },
      { lang: 'it', val: 'Nome file dell’iniziativa' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'InitiativeShowPicture',
    desc: [
      { lang: 'en', val: 'Initiative Show Picture' },
      { lang: 'de', val: 'Initiative Bild anzeigen' },
      { lang: 'es', val: 'Mostrar imagen de la iniciativa' },
      { lang: 'fr', val: 'Afficher l’image de l’initiative' },
      { lang: 'it', val: 'Mostra immagine dell’iniziativa' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'InitiativeDesc',
    desc: [
      { lang: 'en', val: 'Initiative Description' },
      { lang: 'de', val: 'Initiative Beschreibung' },
      { lang: 'es', val: 'Descripción de la iniciativa' },
      { lang: 'fr', val: 'Description de l’initiative' },
      { lang: 'it', val: 'Descrizione dell’iniziativa' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
      { lang: 'es', val: [''] },
      { lang: 'fr', val: [''] },
      { lang: 'it', val: [''] },
    ],
  },
  {
    feId: 'WidgetHeadline_Truecost',
    desc: [
      { lang: 'en', val: 'True Cost' },
      { lang: 'de', val: 'Wahre Kosten' },
      { lang: 'es', val: 'Costo real' },
      { lang: 'fr', val: 'Coût réel' },
      { lang: 'it', val: 'Costo reale' },
    ],
    value: [
      { lang: 'en', val: ['True Cost'] },
      { lang: 'de', val: ['Wahre Folgekosten'] },
      { lang: 'es', val: ['Costo real'] },
      { lang: 'fr', val: ['Coût réel'] },
      { lang: 'it', val: ['Costo reale'] },
    ],
  },
  {
    feId: 'TrueCostProductDesc',
    desc: [
      { lang: 'en', val: 'Product Description' },
      { lang: 'de', val: 'Produkt Beschreibung' },
      { lang: 'es', val: 'Descripción del producto' },
      { lang: 'fr', val: 'Description du produit' },
      { lang: 'it', val: 'Descrizione del prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostProductUVP',
    desc: [
      { lang: 'en', val: 'Product UVP' },
      { lang: 'de', val: 'Produkt UVP' },
      { lang: 'es', val: 'UVP del producto' },
      { lang: 'fr', val: 'UVP du produit' },
      { lang: 'it', val: 'UVP del prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostProduct',
    desc: [
      { lang: 'en', val: 'Product True Cost' },
      { lang: 'de', val: 'Produkt Wahre Kosten' },
      { lang: 'es', val: 'Costo real del producto' },
      { lang: 'fr', val: 'Coût réel du produit' },
      { lang: 'it', val: 'Costo reale del prodotto' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompareDesc',
    desc: [
      { lang: 'en', val: 'Compare Description' },
      { lang: 'de', val: 'Vergleich Beschreibung' },
      { lang: 'es', val: 'Descripción comparativa' },
      { lang: 'fr', val: 'Description comparative' },
      { lang: 'it', val: 'Descrizione comparativa' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompareUVP',
    desc: [
      { lang: 'en', val: 'Compare UVP' },
      { lang: 'de', val: 'Vergleich UVP' },
      { lang: 'es', val: 'Comparar UVP' },
      { lang: 'fr', val: 'Comparer UVP' },
      { lang: 'it', val: 'Confronta UVP' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompare',
    desc: [
      { lang: 'en', val: 'Compare True Cost' },
      { lang: 'de', val: 'Vergleich Wahre Kosten' },
      { lang: 'es', val: 'Comparar costo real' },
      { lang: 'fr', val: 'Comparer le coût réel' },
      { lang: 'it', val: 'Confronta costo reale' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostCauses',
    desc: [
      { lang: 'en', val: 'True Cost Causes' },
      { lang: 'de', val: 'Wahre Kosten Ursachen' },
      { lang: 'es', val: 'Causas del costo real' },
      { lang: 'fr', val: 'Causes du coût réel' },
      { lang: 'it', val: 'Cause del costo reale' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostStudy',
    desc: [
      { lang: 'en', val: 'True Cost Study' },
      { lang: 'de', val: 'Wahre Kosten Studie' },
      { lang: 'es', val: 'Estudio de costo real' },
      { lang: 'fr', val: 'Étude du coût réel' },
      { lang: 'it', val: 'Studio del costo reale' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'TrueCostStudyName',
    desc: [
      { lang: 'en', val: 'True Cost Study' },
      { lang: 'de', val: 'Wahre Kosten Studie' },
      { lang: 'es', val: 'Estudio de costo real' },
      { lang: 'fr', val: 'Étude du coût réel' },
      { lang: 'it', val: 'Studio del costo reale' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Co2',
    desc: [
      { lang: 'en', val: 'CO₂e-Footprint' },
      { lang: 'de', val: 'CO₂e-Fußabdruck' },
      { lang: 'es', val: 'Huella de CO₂e' },
      { lang: 'fr', val: 'Empreinte CO₂e' },
      { lang: 'it', val: 'Impronta CO₂e' },
    ],
    value: [
      { lang: 'en', val: ['CO₂e-Footprint'] },
      { lang: 'de', val: ['CO₂e-Fußabdruck'] },
      { lang: 'es', val: ['Huella de CO₂e'] },
      { lang: 'fr', val: ['Empreinte CO₂e'] },
      { lang: 'it', val: ['Impronta CO₂e'] },
    ],
  },
  {
    feId: 'Co2Values',
    desc: [
      { lang: 'en', val: 'CO₂e Values' },
      { lang: 'de', val: 'CO₂e Werte' },
      { lang: 'es', val: 'Valores de CO₂e' },
      { lang: 'fr', val: 'Valeurs de CO₂e' },
      { lang: 'it', val: 'Valori di CO₂e' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Water',
    desc: [
      { lang: 'en', val: 'Water-Footprint' },
      { lang: 'de', val: 'Wasser-Fußabdruck' },
      { lang: 'es', val: 'Huella Hídrica' },
      { lang: 'fr', val: 'Empreinte Hydrique' },
      { lang: 'it', val: 'Impronta Idrica' },
    ],
    value: [
      { lang: 'en', val: ['Water-Footprint'] },
      { lang: 'de', val: ['Wasser-Fußabdruck'] },
      { lang: 'es', val: ['Huella Hídrica'] },
      { lang: 'fr', val: ['Empreinte Hydrique'] },
      { lang: 'it', val: ['Impronta Idrica'] },
    ],
  },
  {
    feId: 'WaterValues',
    desc: [
      { lang: 'en', val: 'Water Values' },
      { lang: 'de', val: 'Wasser Werte' },
      { lang: 'es', val: 'Valores Hídricos' },
      { lang: 'fr', val: 'Valeurs hydriques' },
      { lang: 'it', val: 'Valori Idrica' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
      { lang: 'es', val: [] },
      { lang: 'fr', val: [] },
      { lang: 'it', val: [] },
    ],
  },
];