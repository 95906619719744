import { IWidget } from 'src/types/product';
import { FooterWidget } from './widgets/_footerWidget';
import { ProductHead } from './widgets/_productHead';
import { HoverButton } from './widgets/_hoverButton';
import { ProductInfo } from './widgets/_productInfo';
import { Certificates } from './widgets/_certificates';
import { createCustomWidget } from './widgets/_customWidget'; // Import function
import { Sustainability } from './widgets/_sustainability';
import { Patos } from './widgets/_patos';
import { Messe } from './widgets/_messe';
import { Recycling } from './widgets/_recycling';
import { Origin } from './widgets/_origin';
import { Storefinder } from './widgets/_storefinder';

// ----------------------------------------------------------------------

// Static widgets array (without CustomWidget)
const staticWidgets: IWidget[] = [
  HoverButton,
  ProductHead,
  ProductInfo,
  FooterWidget,
  Certificates,
  Sustainability,
  Recycling,
  Patos,
  Messe,
  Origin,
  Storefinder,
];

// Hybrid function that combines static widgets and dynamic CustomWidget
export const getWidgets = (): IWidget[] => [
  ...staticWidgets,
  createCustomWidget(), // Generates a new instance each time
];