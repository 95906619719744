import { ILangWidget } from "src/types/product";

export const LangStory: ILangWidget = [
  {
    feId: 'WidgetHeadline_History',
    desc: [
      { lang: 'en', val: 'Story' },
      { lang: 'de', val: 'Geschichte' },
      { lang: 'es', val: 'Historia' },
      { lang: 'fr', val: 'Histoire' },
      { lang: 'it', val: 'Storia' },
    ],
    value: [
      { lang: 'en', val: ['Our story'] },
      { lang: 'de', val: ['Unsere Geschichte'] },
      { lang: 'es', val: ['Nuestra historia'] },
      { lang: 'fr', val: ['Notre histoire'] },
      { lang: 'it', val: ['La nostra storia'] },
    ]
  },
  {
    feId: 'WidgetSubHeadline_History',
    desc: [
      { lang: 'en', val: 'Subheadline story' },
      { lang: 'de', val: 'Zusammenfassung Geschichte' },
      { lang: 'es', val: 'Subtítulo de la historia' },
      { lang: 'fr', val: 'Sous-titre de l’histoire' },
      { lang: 'it', val: 'Sottotitolo della storia' },
    ],
    value: [
      { lang: 'en', val: ['This is our story'] },
      { lang: 'de', val: ['Das ist unsere Geschichte'] },
      { lang: 'es', val: ['Esta es nuestra historia'] },
      { lang: 'fr', val: ['Voici notre histoire'] },
      { lang: 'it', val: ['Questa è la nostra storia'] },
    ]
  },
  {
    feId: 'StoryTag',
    desc: [
      { lang: 'en', val: 'Story Tag' },
      { lang: 'de', val: 'Story Tag' },
      { lang: 'es', val: 'Etiqueta de historia' },
      { lang: 'fr', val: 'Tag d’histoire' },
      { lang: 'it', val: 'Tag della storia' },
    ],
    value: [
      { lang: 'en', val: ['2020'] },
      { lang: 'de', val: ['2020'] },
      { lang: 'es', val: ['2020'] },
      { lang: 'fr', val: ['2020'] },
      { lang: 'it', val: ['2020'] },
    ]
  },
  {
    feId: 'StoryTitle',
    desc: [
      { lang: 'en', val: 'Title' },
      { lang: 'de', val: 'Überschrift' },
      { lang: 'es', val: 'Título' },
      { lang: 'fr', val: 'Titre' },
      { lang: 'it', val: 'Titolo' },
    ],
    value: [
      { lang: 'en', val: ['First Trys', 'Expansion of our idea'] },
      { lang: 'de', val: ['Erste Versuche', 'Ausbau unserer Idee'] },
      { lang: 'es', val: ['Primeros intentos', 'Expansión de nuestra idea'] },
      { lang: 'fr', val: ['Premiers essais', 'Expansion de notre idée'] },
      { lang: 'it', val: ['Primi tentativi', 'Espansione della nostra idea'] },
    ]
  },
  {
    feId: 'StoryPicture',
    desc: [
      { lang: 'en', val: 'Picture' },
      { lang: 'de', val: 'Bild' },
      { lang: 'es', val: 'Imagen' },
      { lang: 'fr', val: 'Image' },
      { lang: 'it', val: 'Immagine' },
    ],
    value: [
      { lang: 'en', val: ['', ''] },
      { lang: 'de', val: ['', ''] },
      { lang: 'es', val: ['', ''] },
      { lang: 'fr', val: ['', ''] },
      { lang: 'it', val: ['', ''] },
    ]
  },
  {
    feId: 'StoryShowPicture',
    desc: [
      { lang: 'en', val: 'Show Picture' },
      { lang: 'de', val: 'Bild anzeigen' },
      { lang: 'es', val: 'Mostrar imagen' },
      { lang: 'fr', val: 'Afficher l’image' },
      { lang: 'it', val: 'Mostra immagine' },
    ],
    value: [
      { lang: 'en', val: ['X', ''] },
      { lang: 'de', val: ['X', ''] },
      { lang: 'es', val: ['X', ''] },
      { lang: 'fr', val: ['X', ''] },
      { lang: 'it', val: ['X', ''] },
    ]
  },
  {
    feId: 'StoryLongDescription',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
      { lang: 'es', val: 'Descripción' },
      { lang: 'fr', val: 'Description' },
      { lang: 'it', val: 'Descrizione' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla at reprehenderit inventore aspernatur sunt.',
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla'
        ],
      },
      {
        lang: 'de',
        val: [
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla at reprehenderit inventore aspernatur sunt.',
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla'
        ],
      },
      {
        lang: 'es',
        val: [
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla at reprehenderit inventore aspernatur sunt.',
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla'
        ],
      },
      {
        lang: 'fr',
        val: [
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla at reprehenderit inventore aspernatur sunt.',
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla'
        ],
      },
      {
        lang: 'it',
        val: [
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla at reprehenderit inventore aspernatur sunt.',
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum, libero quia eveniet, vitae praesentium itaque debitis veritatis cumque laboriosam sint! Minima qui atque nulla'
        ],
      },
    ]
  },
  {
    feId: 'StoryFileName',
    desc: [
      { lang: 'en', val: 'Filename' },
      { lang: 'de', val: 'Datei' },
      { lang: 'es', val: 'Nombre de archivo' },
      { lang: 'fr', val: 'Nom de fichier' },
      { lang: 'it', val: 'Nome del file' },
    ],
    value: [
      { lang: 'en', val: ['', ''] },
      { lang: 'de', val: ['', ''] },
      { lang: 'es', val: ['', ''] },
      { lang: 'fr', val: ['', ''] },
      { lang: 'it', val: ['', ''] },
    ]
  },
];