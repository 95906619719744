import { ILangWidget } from 'src/types/product';

export const LangTextblock: ILangWidget = [
  {
    feId: 'TextBlockContent',
    desc: [
      { lang: 'en', val: 'Textblock' },
      { lang: 'de', val: 'Textblock' },
      { lang: 'es', val: 'Bloque de texto' },
      { lang: 'fr', val: 'Bloc de texte' },
      { lang: 'it', val: 'Blocco di testo' },
    ],
    value: [
      { lang: 'en', val: ['Textblock'] },
      { lang: 'de', val: ['Textblock'] },
      { lang: 'es', val: ['Bloque de texto'] },
      { lang: 'fr', val: ['Bloc de texte'] },
      { lang: 'it', val: ['Blocco di testo'] },
    ],
  },
];