import { ILangWidget } from 'src/types/product';

export const LangSubheading: ILangWidget = [
  {
    feId: 'SubheadingText',
    desc: [
      { lang: 'en', val: 'Subheading' },
      { lang: 'de', val: 'Untertitel' },
      { lang: 'es', val: 'Subtítulo' },
      { lang: 'fr', val: 'Sous-titre' },
      { lang: 'it', val: 'Sottotitolo' },
    ],
    value: [
      { lang: 'en', val: ['Lorem Ipsum'] },
      { lang: 'de', val: ['Lorem Ipsum'] },
      { lang: 'es', val: ['Lorem Ipsum'] },
      { lang: 'fr', val: ['Lorem Ipsum'] },
      { lang: 'it', val: ['Lorem Ipsum'] },
    ],
  },
];